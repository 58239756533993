<template>
  <div class="container">
    <div class="left">
      <div class="deptSearch">
        <el-input
            style="width: 224px"
            placeholder="请输入组织名称"
            suffix-icon="el-icon-search"
            v-model="deptQuery.deptSearch">
        </el-input>
      </div>
      <div class="deptList">
        <el-scrollbar class="quest-scroll">
          <el-tree
              :data="deptQuery.deptData"
              :props="deptQuery.defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :default-expanded-keys="deptQuery.defaultExpandedKeys"
              ref="tree"
              node-key="id"
              highlight-current
              @node-click="handleNodeClick"
          />
        </el-scrollbar>
      </div>
    </div>
    <div class="right">

      <div>
        <div class="screen clearfix">
          <div class="button success" @click="editUser">添加员工</div>
          <div class="button inquire" @click="upload">导入账号</div>
          <div class="button inquire" @click="download">导出账号</div>
        </div>
        <div class="screen clearfix">
          <div class="item">
            <span>员工姓名</span>
            <div class="box">
              <el-input v-model="screen.full_name" placeholder="输入用户姓名" style="width: 180px"></el-input>
            </div>
          </div>
          <div class="item">
            <span>系统账号</span>
            <div class="box">
              <el-input v-model="screen.account" placeholder="输入系统账号" style="width: 180px"></el-input>
            </div>
          </div>
          <div class="item">
            <span>角色</span>
            <div class="box">
              <el-select v-model="screen.role_id" filterable :popper-append-to-body="false" placeholder="全部"
                         style="width: 180px">
                <el-option
                    v-for="item in dictData.roles"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <span>联系电话</span>
            <div class="box">
              <el-input v-model="screen.mobile" placeholder="输入联系电话" style="width: 180px"></el-input>
            </div>
          </div>
          <span class="button inquire" @click="search">查询</span>
          <span class="button" @click="resetQuery">重置</span>
        </div>
        <el-table
            ref="multipleTable"
            :data="tableData.data"
            :border="true"
            v-loading="tableData.loading"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="60"
              align="center">
          </el-table-column>
          <el-table-column
              prop="id"
              label="ID"
              min-width="100%">
          </el-table-column>
          <el-table-column
              prop="full_name"
              label="员工姓名">
          </el-table-column>
          <el-table-column
              prop="account"
              label="系统账号">
          </el-table-column>
          <el-table-column
              prop="job_num"
              label="工号"
              min-width="100%">
          </el-table-column>
          <el-table-column
              prop="organize_node_text"
              label="所属组织">
          </el-table-column>
          <el-table-column
              prop="mobile"
              label="联系电话">
          </el-table-column>
          <el-table-column
              prop="role_text"
              label="角色">
          </el-table-column>
          <el-table-column
              prop="position"
              label="岗位">
          </el-table-column>
          <el-table-column
              prop="job_status"
              :formatter="row => selectDictLabel(dictData.job_status, row.job_status, {label: 'value', value: 'id'})"
              label="在职状态">
          </el-table-column>
          <el-table-column
              prop="create_time"
              :formatter="row => parseTime(row.create_time)"
              label="添加时间">
          </el-table-column>
          <el-table-column
              label="账号状态"
              width="100"
              align="center">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="2"
                  @change="forbiddenUser(scope.row.id, scope.row.status)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="180">
            <template slot-scope="scope">
              <span class="handle" @click="restPwd(scope.row.id)">重置密码</span>
              <span class="handle" @click="editUser(scope.row)">编辑</span>
              <span class="handle" @click="deleteData(scope.row.id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="operate">
          <div class="handle">
            <div class="all-select">
              <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect"
                           @change="toggleSelection()"></el-checkbox>
            </div>
            <div class="button" @click="deleteData()">批量删除</div>
            <div class="button" @click="forbiddenUser()">批量禁用</div>
          </div>
          <el-pagination
              :key="screen.page"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="screen.page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="screen.limit"
              layout="prev, pager, next, sizes"
              :total="tableData.total">
          </el-pagination>
        </div>
      </div>
    </div>

    <import-popup :show="uploadVisible" title="导入员工" template-path="http://ylpt.lzzcloud.net/storage/员工导入模板.xlsx" upload-path="file/upload_single_file"  @close="closeClick" @handleUpload="uploadSuccess"></import-popup>
    <!--  导入员工信息弹窗 -->
<!--    <el-dialog-->
<!--        title="导入员工信息"-->
<!--        :visible.sync="uploadVisible"-->
<!--        width="30%"-->
<!--        :before-close="uploadClose">-->
<!--      <el-upload-->
<!--          ref="upload"-->
<!--          class="upload-demo"-->
<!--          action="/api/file/upload_single_file"-->
<!--          :on-change="handleChange"-->
<!--          :on-success="uploadSuccess"-->
<!--          :before-remove="beforeRemove"-->
<!--          :limit="1"-->
<!--          :file-list="fileList">-->
<!--        <div slot="tip" class="el-upload__tip"><el-link type="primary" @click="downloadTemplate">下载模版</el-link></div>-->
<!--        <el-button size="small" type="primary" v-if="uploadButton">上传附件</el-button>-->
<!--        <el-button size="small" type="primary" @click="reupload" v-else-if="!uploadButton">重新上传</el-button>-->
<!--      </el-upload>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>

import {Message} from "element-ui";
import importPopup from "@/views/questionnaire/components/import-popup.vue"
import {selectDictLabel} from "@/utils/lzzcloud";

const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  organize_id: '',
  full_name: '',
  account: '',
  role_id: null,
  mobile: '',
}


export default {
  name: 'User',
  components: {importPopup},
  data() {
    return {
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      deptQuery: {
        deptSearch: '',
        deptData: [],
        defaultExpandedKeys: [],
        defaultProps: {
          children: "children",
          label: "name"
        },
      },
      dictData: {
        roles: [],
        // 1=在职在岗 2=在职离岗 3=离职
        job_status: [
          {
            id: 1,
            value: '在职在岗'
          },
          {
            id: 2,
            value: '在职离岗'
          },
          {
            id: 3,
            value: '离职'
          }
        ]
      },
      uploadVisible: false,
      uploadButton:true,
      fileList: [],
    }
  },
  watch: {
    'deptQuery.deptSearch': function (val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.screen.page = this.$route.params.page || 1;
    this.getDeptTree()
    this.getDataList()
    this.getRoleOption()
  },
  methods: {
    getRoleOption() {
      this.$api.getRoleOption().then(res => {
        const data = res.data.map(e => {
          return {
            id: e.id,
            value: e.role_name
          }
        })
        this.$set(this.dictData, 'roles', data)
      })
    },
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.deptQuery.deptSearch = '';
      this.search();
    },
    /**
     * 获取字典列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.userList(this.screen).then(res => {
        this.$set(this.tableData, 'data', res.data.data);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    /**
     * 列表数据删除
     */
    deleteData(id) {
      if (!id && this.tableData.ids.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择需要删除的数据！'
        });
        return false;
      }
      let ids = id ? id : this.tableData.ids;

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.delUser({id: ids}).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.resetQuery()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.screen.organize_id = data.id;
      this.getDataList();
    },
    editUser(row) {
      let id = row?.id
      let path = id || id === 0 ? '/editUser' : '/addUser';
      this.$router.push({path, query: {id: id, page: this.screen.page}})
    },
    // 禁用/启用员工
    forbiddenUser(id, status = 2) {
      if (!id && this.tableData.ids.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择需要禁用的数据！'
        });
        return false;
      }
      let ids = id ? id : this.tableData.ids;
      this.$api.editUserStatus({id: ids, status}).then(() => {
        this.$message({
          type: 'success',
          message: status === 2 ? '禁用成功!' : '启用成功！'
        });
        this.getDataList();
      })
    },
    // 重置密码
    restPwd(id) {
      this.$confirm('此操为敏感操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.resetPwd({id: id}).then(() => {
          this.$message({
            type: 'success',
            message: '重置密码成功!'
          });
          this.getDataList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码'
        });
      });
    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      this.$api.getOrganizeTreeList().then(res => {
        this.$set(this.deptQuery, 'deptData', res.data)
        this.$set(this.deptQuery, 'defaultExpandedKeys', this.deptQuery.deptData.map(item => item.id))
      })
    },
    download() {
      this.$api.downloadUser(this.screen).then(res => {
        this.fileDownload(res.data.excel_url);
      })
    },
    upload() {
      this.uploadVisible = true;
    },
    closeClick() {
      this.uploadVisible = false;
    },
    uploadSuccess(response, file, fileList){ //上传成功之后的回调
      this.$api.uploadUser({excel_path:response.data.file_path}).then(()=>{
        Message({type: 'success', message: '上传成功!'});
        setTimeout(() => {
          // this.uploadButton = true;
          this.uploadVisible = false;
          // this.fileList = [];
        }, 500)
      }).catch(() => {
        Message({type: 'error', message: '上传失败，请重新上传!'});
        // this.uploadButton = false;
      })
    },
    beforeRemove(file, fileList) {
      this.uploadButton = true
    },
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.container {
  //margin: 18px;
  margin: 0;
  padding: 18px;
  //background: #;
  display: flex;
  flex-direction: row;
  width: calc(100% - 36px);
  height: calc(100% - 36px);


  .left {
    background: #FFFFFF;
    padding-left: 18px;
    padding-right: 18px;
    //padding-bottom: 30px;
    height: calc(100% - 0px);
    overflow: scroll;
    scrollbar-width: none;

    .deptSearch {
      margin: 19px 0 14px 0;
    }

    .deptList {
      //overflow: auto;
      ////background: #FAFBFC;
      width: 224px;
      height: calc(100% - 80px);

      ::v-deep .quest-scroll {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding-bottom: 10px;

        .el-tree {
          min-width: 224px;

          .el-tree-node__content {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

        .el-scrollbar__wrap {
          overflow-x: hidden;
          align-items: start;
        }
        .el-scrollbar__bar.is-vertical {
          width: 4px;
        }
        .el-scrollbar__thumb {
          background: #A7B2B8;
        }
      }
    }
  }

  .right {
    //flex: 1;
    width: calc(100% - 260px);
    padding-left: 18px;
    height: calc(100% - 0px);
    overflow: scroll;
    scrollbar-width: none;

    > div {
      background: #FFFFFF;
      padding: 10px 20px 26px;

      > div {
        margin: 20px 0;
        padding-bottom: 0;

        .button {
          margin-bottom: 10px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}


</style>
