<template>
  <div class="popup-main">
    <el-dialog :title="title" :visible.sync="show" width="500px" :close-on-click-modal="false"
               :close-on-press-escape="false" @close="closeClick">
      <div class="tip-title" v-if="templatePath">
        请先<span class="upload-btn" @click="download()">下载Excel模版</span>，在模版中填写数据信息。
      </div>
      <div class="select-content flex flex-col-center flex-row-between">
        <span class="file-name">{{ fileName }}</span>
        <div class="select-btn">
          <span>选择</span>
          <input class="file-btn" :accept="accept" type="file" id="file-input" ref="fileInput" :placeholder="placeholder"
                 @change="selectClick"/>
        </div>
      </div>
      <div class="btn-content flex flex-col-center flex-row-center">
        <div class="impor-btn" @click="upload">确定</div>
        <div class="clear-btn" @click="closeClick">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 导入axios实例
import httpRequest from '@/request/index'
function submit(url, data) {
  return httpRequest({
    url: url,
    type: 'file',
    method: 'POST',
    data,
  })
}

export default {
  name: 'import-popup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '单文件上传'
    },
    templatePath: {
      type: String
    },
    uploadPath: {
      type: String
    },
    accept: {
      type: String,
      default: '.xls,.xlsx'
    },
    placeholder: {
      type: String,
      default: '请选择文件'
    }
  },
  data() {
    return {
      fileName: '',//选择的文件名称
      formData: null
    }
  },
  methods: {
    //选择文件
    selectClick(e) {
      this.fileName = e.target.value;
      const file = e.target.files;
      // let extName = file[0].name
      //     .substring(file[0].name.lastIndexOf("."))
      //     .toLowerCase();

      let formData = new FormData();
      formData.append("file", file[0]);
      this.formData = formData
    },
    //关闭
    closeClick() {
      this.formData = null;
      this.fileName = '';
      const input = document.getElementById('file-input');
      input.value = ''
      this.$emit('close')
    },
    download() {
      this.fileDownload(this.templatePath)
    },
    upload() {//导入
      const _this = this;
      submit(this.uploadPath, this.formData).then(res => {
        _this.$emit('handleUpload', res);
        this.formData = null;
        this.fileName = '';
        const input = document.getElementById('file-input');
        input.value = ''
      }).catch(() => {
        this.formData = null;
        this.fileName = '';
        const input = document.getElementById('file-input');
        input.value = ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-main {
  .tip-title {
    font-size: 12px;
    color: #242D33;
    margin-left: 30px;

    .upload-btn {
      color: #3984F5;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .select-content {
    margin: 22px 30px 0 30px;

    .file-name {
      width: 330px;
      height: 34px;
      line-height: 34px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #CEDAE0;
      padding: 0 12px;
      box-sizing: border-box;
      color: #242D33;
      font-size: 12px;
      overflow: hidden;
    }

    .select-btn {
      margin-left: 10px;
      width: 60px;
      height: 34px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #3984F5;
      line-height: 34px;
      text-align: center;
      font-size: 12px;
      color: #3984F5;
      position: relative;

      &:hover {
        cursor: pointer;
        color: #fff;
        background: #3984F5;
      }

      .file-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .btn-content {
    margin-top: 32px;

    .impor-btn {
      width: 90px;
      height: 40px;
      background: #3984F5;
      border-radius: 4px;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      text-align: center;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    .clear-btn {
      width: 90px;
      height: 40px;
      background: #EEF1F3;
      border-radius: 4px;
      line-height: 40px;
      font-size: 14px;
      color: #6C7880;
      text-align: center;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }
}

</style>